var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',_vm._b({key:_vm.rmaValues.uid,attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},'v-dialog',_vm.$attrs,false),[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"accent"}},[_c('v-toolbar-title',[_vm._v("RMA Details")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.isOpen = false}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1)],1),(_vm.rmaValues.uid)?_c('div',[_c('v-card-title',[_c('span',{staticClass:"headline",attrs:{"data-cy":"rma-details-rma-number"}},[_vm._v(" RMA# "+_vm._s(_vm.rmaValues.uid)+" "),(
                                    _vm.rmaValues.rmaType ===
                                    'GVS_ADVANCE_EXCHANGE'
                                )?_c('span',[_vm._v("(Advance Exchange)")]):_vm._e()]),_c('v-spacer'),(
                                _vm.rmaValues.flags.allowFedexGroundPickup &&
                                _vm.rmaValues.flags.allowCancel
                            )?_c('base-btn',{staticClass:"mr-2",attrs:{"color":"blue darken-1","outlined":""},on:{"click":_vm.scheduleGroundReturnPickup}},[_vm._v(" FedEx Ground Pickup ")]):_vm._e(),(_vm.rmaValues.flags.allowRepairSummary)?_c('base-btn',{staticClass:"mr-2",attrs:{"color":"blue darken-1","outlined":""},on:{"click":_vm.repairSummary}},[_vm._v(" Repair Summary ")]):_vm._e(),_c('base-btn',{attrs:{"color":"green darken-1","outlined":""},on:{"click":function($event){;(_vm.isOpen = false), (_vm.sendMessage = true)}}},[_vm._v(" need help? ")])],1),(
                            _vm.rmaValues.flags.allowFedexGroundPickup &&
                            _vm.rmaValues.flags.allowCancel
                        )?_c('div',{staticClass:"caption px-3 mx-3"},[_vm._v(" * It may take up to several hours for FedEx to recognize a new Ground Return tracking number to schedule a pick-up.")]):_vm._e(),(_vm.summaryError)?_c('v-alert',{staticClass:"mx-4",attrs:{"type":"error","outlined":""}},[_vm._v(" Error Generating Repair Summary Report ")]):_vm._e(),_c('base-card-summary',{attrs:{"tile":"","flat":"","data-map":_vm.summaryMap,"all-data":_vm.rmaValues},scopedSlots:_vm._u([{key:"problem",fn:function(ref){
                        var item = ref.item;
                        var root = ref.root;
return [_vm._v(" "+_vm._s([ item ].concat( (root.additionalProblems || []) ).join(', '))+" ")]}},{key:"customerStatus",fn:function(ref){
                        var item = ref.item;
return [_c('span',{attrs:{"data-cy":"rma-details-latest-status"}},[_vm._v(" "+_vm._s(item))])]}},{key:"stage.preQuote.quoteId",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" "),_c('br'),_c('service-details-quote',{attrs:{"quote-id":item}})]}},{key:"createdAt",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item))+" ")]}},{key:"closedAt",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item))+" ")]}},{key:"unit.endDeviceWarranty",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item))+" ")]}},{key:"tracking.inbound.labelUrl",fn:function(ref){
                        var item = ref.item;
return [_c('a',{attrs:{"href":item,"target":"_blank"}},[_vm._v(" Download PDF ")])]}},{key:"stage.shipAdvanceExchange.parts.0.partSerial",fn:function(ref){
                            var item = ref.item;
                            var root = ref.root;
return [_c('a',{attrs:{"href":root.tracking.outbound.trackingUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item)+" ")])]}},{key:"tracking.inbound.trackingNumber",fn:function(ref){
                            var item = ref.item;
                            var root = ref.root;
return [_c('a',{attrs:{"href":root.tracking.inbound.trackingUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item)+" ")])]}},(
                                _vm.rmaValues.tracking &&
                                _vm.rmaValues.tracking.outbound &&
                                _vm.rmaValues.tracking.outbound.trackingUrl
                            )?{key:"tracking.outbound.trackingNumber",fn:function(ref){
                            var item = ref.item;
                            var root = ref.root;
return [_c('a',{attrs:{"href":root.tracking.outbound &&
                                    root.tracking.outbound.trackingUrl &&
                                    root.tracking.outbound.trackingUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item)+" ")])]}}:null],null,true)}),_c('v-card-actions',{class:_vm.$vuetify.breakpoint.smAndDown &&
                            'd-flex flex-column'},[(_vm.rmaValues.flags.allowCancel)?_c('base-btn',{class:_vm.$vuetify.breakpoint.smAndDown && 'mb-2',attrs:{"data-cy":"btn-cancel-rma","color":"red","loading":_vm.cancellingRma,"block":_vm.$vuetify.breakpoint.smAndDown,"outlined":""},on:{"click":_vm.cancelRma}},[_vm._v("Cancel RMA ")]):_vm._e(),(_vm.cancelError)?_c('v-alert',{class:_vm.$vuetify.breakpoint.smAndDown
                                    ? 'mb-2'
                                    : 'ml-2',attrs:{"block":"","type":"error"}},[_vm._v(" Error Canceling RMA ")]):_vm._e(),_c('v-spacer'),_c('base-btn',{attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"outlined":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v("Close Details ")])],1)],1):_c('div',[_c('v-card-text',[_vm._v(" No RMA details have been provided from our repair center. If this is an error, please send us a message. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","outlined":""},on:{"click":function($event){;(_vm.isOpen = false), (_vm.sendMessage = true)}}},[_vm._v(" Send a Message ")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"600","fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.sendMessage),callback:function ($$v) {_vm.sendMessage=$$v},expression:"sendMessage"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Create Case")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.sendMessage = false}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1)],1),_c('v-card-text',[_c('sf-form',{key:(_vm.rmaValues && _vm.rmaValues.uid) + _vm.caseKeyState,attrs:{"outlined":"","dense":"","serial-number":_vm.rmaValues && _vm.rmaValues.unit && _vm.rmaValues.unit.serial,"model-name":_vm.rmaValues && _vm.rmaValues.unit && _vm.rmaValues.unit.model,"align-button":"right","rma-number":_vm.rmaValues && _vm.rmaValues.uid,"private-form":""},on:{"change":function($event){_vm.caseKeyState++},"complete":function($event){_vm.sendMessage = false}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }