<template>
    <v-container>
        <base-hero-simple class="mb-2">
            <base-router-links :items="links" />
        </base-hero-simple>

        <!-- <schedule-pickup :address="oidcShipping" class="text-right mb-4" /> -->
        <rma-history-table id="rma-history-table" class="mt-4" />
    </v-container>
</template>
<script>
// import SchedulePickup from '../../../components/schedule_pickup'

import { navLinks } from '../navigation.js'
import RmaHistoryTable from './rma_history_table'
export default {
    name: `RmaHistory`,
    components: {
        RmaHistoryTable
        // SchedulePickup
    },

    metaInfo: { title: `Your Account`, subTitle: `Rma History` },

    data() {
        return {
            links: navLinks
        }
    },

    computed: {
        oidcShipping() {
            return this.$auth.user.address
                ? this.$auth.user.address.shipping
                : null
        }
    }
}
</script>
