<template>
    <div>
        <v-progress-circular
            v-if="loading"
            indeterminate
            size="24"
            class="mt-3 ml-3"
        />
        <v-card v-if="!loading && details" class="mt-2" outlined flat>
            <v-simple-table dense>
                <template v-slot>
                    <tbody>
                        <tr v-for="(item, index) in details.items" :key="index">
                            <td>{{ item.name }}</td>
                            <td>${{ item.total.toFixed(2) }}</td>
                        </tr>
                        <tr>
                            <th>Subtotal</th>
                            <td>${{ details.subtotal.toFixed(2) }}</td>
                        </tr>
                        <tr>
                            <th>Tax</th>
                            <td>${{ details.amount.tax.toFixed(2) }}</td>
                        </tr>
                        <tr>
                            <th>Total</th>
                            <td>${{ details.total.toFixed(2) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <v-alert
            v-if="error"
            type="error"
            dense
            tile
            outlined
            class="mx-0 pa-1 pl-3"
        >
            Could not load paid quote information
        </v-alert>
    </div>
</template>

<script>
import { paidQuoteDetails } from '@/api'

export default {
    name: 'ServiceDetailsQuote',
    props: {
        quoteId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            error: false,
            details: null
        }
    },
    watch: {
        quoteId: {
            immediate: true,
            handler() {
                if (this.quoteId) {
                    this.getPaidQuoteDetails()
                }
            }
        }
    },
    methods: {
        async getPaidQuoteDetails() {
            if (this.quoteId) {
                await paidQuoteDetails({ quoteId: this.quoteId })
                    .then(({ data }) => {
                        this.details = data
                    })
                    .catch((e) => {
                        console.error(e)
                        this.error = true
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
}
</script>
