var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rma-history"},[_c('base-text-field',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.searchFromFilter),expression:"searchFromFilter"}],staticClass:"mb-3",attrs:{"append-icon":_vm.mdiMagnify,"label":"Filter RMA history..","single-line":"","clearable":"","dark":"","hide-details":""},on:{"click:clear":_vm.clearFilter},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"server-items-length":_vm.items && _vm.items[0] && _vm.items[0].itemCount,"loading":_vm.rmasLoading,"headers":_vm.headers,"options":_vm.options,"items":_vm.items,"data-cy":"rma-history-table","footer-props":_vm.optionsDepOnBrowser,"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var remove = ref.remove;
var toggle = ref.toggle;
var group = ref.group;
var groupBy = ref.groupBy;
return [_c('td',{key:group,staticClass:"text-start",attrs:{"colspan":"9"},on:{"click":toggle}},[_c('button',{staticClass:"ma-0 v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small",attrs:{"type":"button"}},[_c('span',{staticClass:"v-btn__content"},[_c('v-icon',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm.mdiMinus))])],1)]),_vm._v(" "+_vm._s(_vm.groupByDisplay(groupBy))+": "+_vm._s(group)+" "),_c('button',{staticClass:"ma-0 v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small",attrs:{"type":"button"},on:{"click":remove}},[_c('span',{staticClass:"v-btn__content"},[_c('v-icon',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm.mdiClose))])],1)])])]}},{key:"item.problem",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" "),(
                    item.additionalProblems &&
                    item.additionalProblems.length
                )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('a',_vm._g({},on),[_vm._v(" (+"+_vm._s(item.additionalProblems.length)+" items) ")])]}}],null,true)},_vm._l((item.additionalProblems),function(e){return _c('span',{key:e},[_vm._v(_vm._s(e)),_c('br')])}),0):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.closedAt",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.closedAt))+" ")]}},{key:"item.unit.serial",fn:function(ref){
                var item = ref.item;
return [_c('span',{attrs:{"data-cy":("rma-serial-" + (item.unit.serial))}},[_vm._v(" "+_vm._s(item.unit.serial)+" ")])]}},{key:"item.customerStatus",fn:function(ref){
                var item = ref.item;
return [_c('span',{attrs:{"data-cy":("rma-status-" + (item.uid))}},[_vm._v(" "+_vm._s(item.customerStatus)+" ")])]}},{key:"item.rmaType",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.rmaType === 'GVS_ADVANCE_EXCHANGE' ? 'Yes' : 'No')+" ")]}},{key:"item.uid",fn:function(ref){
                var item = ref.item;
return [_c('a',{attrs:{"data-cy":("link-open-rma-details-" + (item.uid))},on:{"click":function($event){return _vm.exposeRmaDetails(item.uid)}}},[_vm._v(_vm._s(item.uid))])]}},{key:"item.tracking.inbound.trackingNumber",fn:function(ref){
                var item = ref.item;
return [_c('a',{attrs:{"href":item.tracking &&
                    item.tracking.inbound &&
                    item.tracking.inbound.trackingUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item.tracking && item.tracking.inbound && item.tracking.inbound.trackingNumber))])]}},{key:"item.tracking.outbound.trackingNumber",fn:function(ref){
                    var item = ref.item;
return [_c('a',{attrs:{"href":item.tracking &&
                    item.tracking.outbound &&
                    item.tracking.outbound.trackingUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item.tracking && item.tracking.outbound && item.tracking.outbound.trackingNumber))])]}}])}),_c('service-details',{ref:"serviceDetails",attrs:{"rma-values":_vm.singleRmaDetails,"width":"900px"},on:{"reloadHistory":_vm.fetchRmas}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }